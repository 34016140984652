import { useEffect, useState } from "react"
import { fetcher } from "../../utils/helper";
import { Select, Table } from "antd";
import { NewSearchBox } from "../common/NewSearchBox";
import { toast, ToastContainer } from "react-toastify";

export const ChangeRole = () =>{
    const [userList , setUserList] = useState([]);
    const [cloneUserList , setCloneUserList] = useState([]);
    const [searchData , setSearchData] = useState('');

    const handleChangeGovernifyRole = async (e , item) =>{
        try{
          const response = await fetcher(`governifyprofile/superadmin/updateUserRole` , 'POST' , JSON.stringify({user_id: item.id , governify_role:e}));
         if(response.status){
          toast.success('Governify Role Updated Successfully.');
          getAllUser();
         }
        }catch(err){
          console.log(err , 'error')
        }
    }


    const columns = [
        {
          title: "ID",
          dataIndex: "id",
          width: 60,
        },
        {
          title: "Name",
          dataIndex: "name",
          width: 200,
        },
        {
          title: "Email",
          dataIndex: "email",
        },
        {
          title: "Role",
          dataIndex: "",
          key: "x",
          render: (_, record) => (
            <div style={{ display: "flex", gap: "4px" }}>   
            <Select
             style={{ width: "100%", borderRadius: "10px" }}
                value={record.governifyRole}
                onChange={(e)=>handleChangeGovernifyRole(e , record)}
                options={[{label:'User' , value:'0'} ,{label:'Admin' , value:'2'}]}
            />
            </div>
          ),
          width: 300,
          fixed: "right",
        },
      ];

    const  getAllUser = async() =>{
        try{
           const response = await fetcher(`governifyprofile/superadmin/getAllUser` , 'GET');
           if(response.status){
            let tempUserList = response.response.map((item)=>{
                return { id: item.id , email:item.email , name: item.name , governifyRole: item.governify_role};
            })
            setUserList(tempUserList);
            setCloneUserList(tempUserList);
           }
        }catch(err){
            console.log(err , 'error');
        }
    }

    useEffect(() => {
        const cloneData = [...userList];
        const tempSearchData = [];
        if (searchData.length > 0) {
          cloneData.forEach((item) => {
            if (
              item.name.toLowerCase().includes(searchData.toLowerCase()) ||
              item.email.toLowerCase().includes(searchData.toLowerCase())
            ) {
              tempSearchData.push(item);
            }
          });
          setUserList(tempSearchData);
        } else {
          setUserList(cloneUserList);
        }
      }, [searchData]);

    useEffect(()=>{
      getAllUser()
    } ,[]);
    
    return (
        <div style={{marginTop:"10px"}}>

        <div style={{ marginBottom: "20px" }}>
          <NewSearchBox
            placeHolder={"Start Typing To Search By Name"}
            setSearchData={setSearchData}
            flag={false}
          />
        </div>
        <Table  
          columns={columns}
          dataSource={userList}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
          scroll={{ x: 768 }}
          pagination={{
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: 5,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 15, 20],
            defaultCurrent: 1,
          }}
        />
          <ToastContainer position="bottom-right" className="custom-toast" />
        </div>
    )
}