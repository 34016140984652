import { useEffect, useState } from "react";
import { Button, Modal, Table, Typography } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Loader } from "../common/Loader";
import { ToastContainer } from "react-toastify";
import { CreateProfileModal } from "./CreateProfileModal";
import { EditProfileModal } from "./EditProfileModal";
import { fetcher } from "../../utils/helper";

export const Profile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState({
    flag: false,
    id: "",
  });
  const [createProfileModalFlag, setCreateProfileModalFlag] = useState(false);
  const [editProfileModalFlag, setEditProfileModalFlag] = useState(false);
  const [profileDataToEdit, setProfileDataToEdit] = useState({});
  const [showSkeleton, setShowSkeleton] = useState(true);

  const settingData = JSON.parse(sessionStorage.getItem("settings")) || {
    image: "https://onboardify.tasc360.com/uploads/y22.png",
    site_bg: "#ffffff",
    button_bg: "#497ed8",
    banner_bg: "#497ed8",
    banner_content:
      "Hire an attitude, not just experience and qualification. Greg Savage.",
    header_bg: "#f7f7f7",
    head_title_color: "#497ed8",
  };

  const handleCreateProfile = () => {
    setCreateProfileModalFlag(true);
  };

  const handleDeleteModal = (id) => {
    setDeleteModalOpen({ flag: true, id: id });
  };

  const handleEditProfile = (item) => {
    let tempItem = { ...item };
    tempItem.users = item.users.split(",");
    setProfileDataToEdit(tempItem);
    setEditProfileModalFlag(true);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 60,
    },
    {
      title: "Name",
      dataIndex: "title",
      width: 200,
    },
    {
      title: "User List",
      dataIndex: "users",
    },
    {
      title: "Filter",
      dataIndex: "selectedColumnValue",
    },
    {
      title: "Logo",
      dataIndex: "logo_location",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "4px" }}>
          <img
            src={record.logo_location}
            width="100px"
            height="50px"
            alt="No Logo"
          />
        </div>
      ),
      width: 150,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "4px" }}>
          <Button
            className="governify-delete-icon"
            type="plain"
            icon={<EditOutlined />}
            onClick={() => handleEditProfile(record)}
          ></Button>

          <Button
            className="governify-delete-icon"
            type="plain"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteModal(record.id)}
          ></Button>
        </div>
      ),
      width: 100,
      fixed: "right",
    },
  ];

  const handleBackNavigation = () => {
    navigate("/admin");
  };

  const getListOfAllProfiles = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `governifyprofile/superadmin/getGovernifyProfiles`,
        "GET"
      );
      if (response.status) {
        let tempListing = response.response.map((item) => {
          return {
            id: item.id,
            title: item.profile_name,
            users: item.users,
            logo: item.logo,
            selectedColumn: JSON.parse(item.filter).selectedColumn,
            selectedColumnValue: JSON.parse(item.filter).selectedColumnValue,
            selectedBoard: JSON.parse(item.filter).selectedBoard,
            logo_location: item.logo_location,
            category: item.governify_category.split(',').map((detail)=> Number(detail))
          
          };
        });
        setDataSource(tempListing);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const handleCancelDeleteProfile = () => {
    let tempData = { ...deleteModalOpen };
    tempData.flag = false;
    setDeleteModalOpen(tempData);
  };

  const handleDeleteProfile = async () => {
    setLoading(true);
    try {
      const response = await fetcher(
        `governifyprofile/superadmin/destroy/${deleteModalOpen.id}`,
        "DELETE"
      );

      if (response.status) {
        getListOfAllProfiles();
        setDeleteModalOpen({ flag: false, id: "" });
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListOfAllProfiles();
  }, []);

  return (
    <div className="pt-84">
      {loading && <Loader />}

      <div>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            icon={
              <LeftOutlined
                style={{
                  color: settingData.button_bg,
                  borderColor: settingData.button_bg,
                }}
              />
            }
            onClick={handleBackNavigation}
            style={{ border: `1px solid ${settingData.button_bg}` }}
          ></Button>
          <Button
            icon={
              <PlusOutlined
                style={{
                  color: settingData.button_bg,
                  borderColor: settingData.button_bg,
                }}
              />
            }
            onClick={handleCreateProfile}
            style={{
              border: `1px solid ${settingData.button_bg}`,
              color: settingData.button_bg,
            }}
          >
            Create Profile
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
          scroll={{ x: 768 }}
          pagination={{
            showTotal: (total) => `Total ${total} items`,
            defaultPageSize: 5,
            showQuickJumper: true,
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 15, 20],
            defaultCurrent: 1,
          }}
        />
      </div>

      <Modal
        open={createProfileModalFlag}
        centered
        footer={(_, record) => <></>}
        onCancel={() => setCreateProfileModalFlag(false)}
        width={900}
      >
        <CreateProfileModal
          setShowSkeleton={setShowSkeleton}
          setLoading={setLoading}
          loading={loading}
          setModalOpen={setCreateProfileModalFlag}
          getListOfAllProfiles={getListOfAllProfiles}
        />
      </Modal>

      <Modal
      key={profileDataToEdit.id}
        open={editProfileModalFlag}
        centered
        footer={(_, record) => <></>}
        onCancel={() => {setEditProfileModalFlag(false); setProfileDataToEdit({})}}
        width={900}
      >
        <EditProfileModal
          setShowSkeleton={setShowSkeleton}
          setLoading={setLoading}
          loading={loading}
          setModalOpen={setEditProfileModalFlag}
          profileDataToEdit={profileDataToEdit}
          getListOfAllProfiles={getListOfAllProfiles}
        />
      </Modal>

      <Modal
        open={deleteModalOpen.flag}
        title="Delete Profile"
        centered
        footer={(_, record) => (
          <>
            <Button
              style={{
                background: settingData.button_bg,
                color: "#fff",
                border: "none",
              }}
              onClick={handleDeleteProfile}
            >
              Delete
            </Button>
            <Button
              style={{ border: "none" }}
              onClick={handleCancelDeleteProfile}
            >
              Cancel
            </Button>
          </>
        )}
        onCancel={handleCancelDeleteProfile}
      >
        <Typography>Are you sure you want to delete this Profile?</Typography>
      </Modal>

      <ToastContainer position="bottom-right" />
    </div>
  );
};
