import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer, Input, Select, Space } from "antd";
import { useMediaQuery } from "react-responsive";

export const DrawerToInviteUserForAccessProfile = ({
  openDrawerInviteUser,
  setOpenDrawerInviteUser,
  inviteUserData,
  handleInviteUser,
  handleChangeInviteUserEmail,
  dataSourceAccessProfile,
  handleChangeInviteUserRole,
  handleChangeInviteUserProfile,
}) => {
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  let DrawerWidth = "520px";
  if (isLargeScreen) {
    DrawerWidth = "520px";
  } else if (isMediumScreen) {
    DrawerWidth = "520px";
  } else if (isSmallScreen) {
    DrawerWidth = "90%";
  }

  const accessProfileData = dataSourceAccessProfile.map((item) => {
    return { label: item.title, value: item.id };
  });

  return (
    <div>
      <Drawer
        zIndex={9991}
        width={DrawerWidth}
        title={<div className="fs-24 pt-1">Add Team Member</div>}
        open={openDrawerInviteUser}
        closable={false}
        onClose={() => setOpenDrawerInviteUser(false)}
        extra={
          <Space>
            <Button onClick={() => setOpenDrawerInviteUser(false)} type="text">
              <CloseOutlined />
            </Button>
          </Space>
        }
        footer={
          <div className="d-flex justify-content-between pt-2">
            <button
              style={{
                background: "#f2f3f7",
                color: "#202223",
                maxWidth: "224px",
                borderRadius: "8px",
                gap: "5px",
                padding: "10px 20px",
                border: "none",
              }}
              onClick={() => setOpenDrawerInviteUser(false)}
            >
              Cancel
            </button>
            <button
              style={{
                background: "#00BF63",
                color: "#fff",
                maxWidth: "224px",
                borderRadius: "8px",
                gap: "5px",
                padding: "10px 20px",
                border: "none",
              }}
              onClick={handleInviteUser}
            >
              Invite User
            </button>
          </div>
        }
      >
        <div className="d-flex flex-column" style={{ gap: "20px" }}>
          <div className="d-flex mt-2 flex-column" style={{ gap: "5px" }}>
            <div style={{ marginBottom: "10px" }}>
              <p style={{ marginBottom: "5px" }}>Email</p>
              <Input
                value={inviteUserData.email}
                onChange={handleChangeInviteUserEmail}
                placeholder="Enter Email"
              />
            </div>

            <div style={{ marginBottom: "10px" }}>
              <p style={{ marginBottom: "5px" }}>Role</p>
              <Select
                style={{ width: "100%" }}
                value={inviteUserData.role}
                onChange={handleChangeInviteUserRole}
                options={[
                  { label: "User", value: "0" },
                  { label: "Admin", value: "2" },
                ]}
                placeholder="Enter Role"
              />
            </div>

            <div style={{ marginBottom: "10px" }}>
              <p style={{ marginBottom: "5px" }}>Access Profile</p>
              {inviteUserData.role === "0" ? (
                <Select
                  style={{ width: "100%" }}
                  value={inviteUserData.profile}
                  onChange={handleChangeInviteUserProfile}
                  options={accessProfileData}
                  placeholder="Enter Access Profile"
                />
              ) : (
                <Select
                  style={{ width: "100%" }}
                  value={"3"}
                  onChange={handleChangeInviteUserProfile}
                  options={[{ label: "Admin have Full Access", value: "3" }]}
                  placeholder="Enter Access Profile"
                  disabled
                />
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
