import { CloseOutlined } from "@ant-design/icons";
import { Button, Collapse, Drawer, Input, Space } from "antd";
import { useMediaQuery } from "react-responsive";

export const DrawerToEditAccessProfile = ({
    openEditDrawerProfile ,
    setOpenEditDrawerProfile ,
    handleEditProfile ,
    updateProfileData ,
    handleEditProfileName ,
    handleEditProfileDescription ,
    activeKeys ,
    getItems 
}) => {
  const isLargeScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  let DrawerWidth = "520px";
  if (isLargeScreen) {
    DrawerWidth = "520px";
  } else if (isMediumScreen) {
    DrawerWidth = "520px";
  } else if (isSmallScreen) {
    DrawerWidth = "90%";
  }

  console.log(updateProfileData , 'updateProfileData')

  return (
    <Drawer
      zIndex={9991}
      width={DrawerWidth}
      title={<div className="fs-24 pt-1">Edit Access Profiles</div>}
      open={openEditDrawerProfile}
      closable={false}
      onClose={() => setOpenEditDrawerProfile(false)}
      extra={
        <Space>
          <Button onClick={() => setOpenEditDrawerProfile(false)} type="text">
            <CloseOutlined />
          </Button>
        </Space>
      }
      footer={
        <div className="d-flex justify-content-between pt-2">
          <button
            style={{
              background: "#f2f3f7",
              color: "#202223",
              maxWidth: "224px",
              borderRadius: "8px",
              gap: "5px",
              padding: "10px 20px",
              border: "none",
            }}
            onClick={() => setOpenEditDrawerProfile(false)}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#00BF63",
              color: "#fff",
              maxWidth: "224px",
              borderRadius: "8px",
              gap: "5px",
              padding: "10px 20px",
              border: "none",
            }}
            onClick={handleEditProfile}
          >
            Save Changes
          </button>
        </div>
      }
    >
      <div className="d-flex flex-column" style={{ gap: "20px" }}>
        <div className="d-flex mt-2 flex-column" style={{ gap: "5px" }}>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ marginBottom: "5px" }}>Access Profile Name</p>
            <Input
              value={updateProfileData.title}
              onChange={handleEditProfileName}
              placeholder="Enter access profile name"
            />
          </div>

          <div style={{ marginBottom: "10px" }}>
            <p style={{ marginBottom: "5px" }}>Description</p>
            <Input
              value={updateProfileData.description}
              onChange={handleEditProfileDescription}
              placeholder="Enter access profile description"
            />
          </div>

          <div style={{ marginBottom: "14px" }}>
            <p
              style={{
                marginBottom: "5px",
                fontSize: "20px",
                fontWeight: "700",
              }}
            >
              System Privileges
            </p>

            {/* <Collapse
                bordered={false}
                items={getItems()}
              /> */}
            <Collapse
              className="collapse-profile"
              bordered={false}
              activeKey={activeKeys}
              onChange={() => {} /* Prevent default toggle */}
              items={getItems()}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};
