import { Button, Table } from "antd";
import { CreatePlusIcon } from "../../assets/image";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export const ManageAccessProfileTable = ({setOpenDrawerProfile , dataSourceAccessProfile , handleEditDrawerAccessProfile , handleDeleteDrawerAccessProfile}) =>{

    const columnsManageProfile = [
        {
          title: "Access Profil Name",
          dataIndex: "title",
          key: "title",
          width: 100,
        },
        {
          title: "Assigned user(s)",
          dataIndex: "users",
          key: "users",
          width: 100,
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
          width: 200,
        },
    
        {
          title: "",
          dataIndex: "",
          key: "x",
          render: (_, record) => (
            <div>
              <Button
                className="governify-delete-icon"
                type="plain"
                icon={<EditOutlined />}
                onClick={() => handleEditDrawerAccessProfile(record)}
              ></Button>
    
              <Button
                className="governify-delete-icon"
                type="plain"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteDrawerAccessProfile(record)}
              ></Button>
            </div>
          ),
          width: 35,
          fixed: "right",
        },
      ];


    return (
        <div>
            <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "white",
              padding: "16px",
            }}
          >
            <span style={{ fontSize: "24px", fontyWeight: "600" }}>
              Manage Access Profiles
            </span>

            <Button
              style={{
                background: "#5ac063",
                color: "white",
                border: "none",
                fontWeight: 600,
                height: "35px",
              }}
              icon={<CreatePlusIcon />}
              iconPosition="end"
              onClick={() => {
                setOpenDrawerProfile(true);
              }}
            >
              Create Access Profile
            </Button>
          </div>

          <Table
            scroll={{ x: 768 }}
            className="governify-report-table custom-table"
            columns={columnsManageProfile}
            dataSource={dataSourceAccessProfile}
            pagination={{ className: "custom-pagination" }}
          />
        </div>
    )
}