import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { fetcher, getRole, getToken, isTokenValid } from "../../utils/helper";
import { userSettingData } from "../../utils/tools";
import { Optionslist } from "../admin/Optionslist";
import { Button, Select, Table } from "antd";
import Hero from "../common/Hero";
import { ExportReportViewIcon } from "../../assets/image";
import { DeleteOutlined } from "@ant-design/icons";

export const ProfileAdminHome = () => {
  const navigate = useNavigate();
  const role = getRole();
  const token = getToken();
  const [userCategoryList , setUserCategoryList] = useState([{user:'' , catergory:[]}]);

//   useEffect(() => {
//     if (role === undefined || role === null || role === "") {
//       navigate("/signin");
//     }
//     if (role === "customer") {
//       if (token !== null && token !== undefined && token !== "") {
//         let status = isTokenValid(token);
//         if (status.valid) {
//           navigate("/");
//         } else {
//           navigate("/signin");
//         }
//       } else {
//         navigate("/signin");
//       }
//     }
//   }, [role, navigate]);

const fetchProfileData = async() =>{
     try{
         const response = await fetcher(`governifyprofile/admin/getProfileByUserId/${sessionStorage.getItem('userId')}` , 'GET');
         if(response.status){
            let tempUserList = response.response.users.split(','); 
            let newTempUserList = tempUserList.filter((item) => item  !== sessionStorage.getItem('userEmail'));
            setUserCategoryList({user:newTempUserList , category:[]});
         }
     }catch(err){

     }finally{

     }
}

  const filterOption = (input, option) => {
    return (
      option.label.toLowerCase().includes(input.toLowerCase()) ||
      option.value.toString().toLowerCase().includes(input.toLowerCase())
    );
  };

  const columns = [
   
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 100,
    },
   
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },

    {
      title: "Access Profile",
      dataIndex: "email",
      key: "email",
      width: 200,
    },
    
    {
      title: "Invitation Date",
      dataIndex: "status",
      key: "status",
      width: 200,
    },
    
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    
 
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Button
          className="governify-delete-icon"
          type="plain"
          icon={<DeleteOutlined />}
          onClick={() => {}}
        ></Button>
      ),
      width: 80,
      fixed: "right",
    },
  ];


  useEffect(()=>{
    fetchProfileData()
    } ,[])

  useEffect(() => {
    userSettingData();
    return () => {};
  }, []);

  return (
  <>
   	<Hero
				heading={"Team Management"}
				subheading="Stay Informed and in control of the overall status of your requests."
				forHome={true}
			/>
      <div  
          style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              background: "white",
              padding: "16px",
              paddingLeft: "0px"
            }}>
              <Button>Team Members</Button><Button style={{marginLeft:"10px"}}>Manage Access Profiles</Button></div>

         <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "white",
              padding: "16px",
            }}
          >
            <span>Team Members</span>
      
            <Button
              style={{
                background: "#5ac063",
                color: "white",
                border: "none",
                fontSize: "16px",
                fontWeight: 600,
                height: "35px",
              }}
              icon={<ExportReportViewIcon />}
              iconPosition="start"
              onClick={()=>{}}
            >
              Add Team Members
            </Button>
          </div>
        

      <Table
        scroll={{ x: 768 }}
        className="governify-report-table custom-table"
        columns={columns}
        dataSource={[]}
        pagination={{ className: "custom-pagination" }}
      />
    </>
  );
};
