import {
  FileTextOutlined,
  MenuOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Dropdown } from "antd";
import React from "react";
import {
  CheckListIcon,
  Credentials,
  Logout,
  MyProfileNavIcon,
  TeamMemberNavIcon,
} from "../../utils/Icons";
import { useLocation, useNavigate } from "react-router-dom";

const CustomerMenu = ({ logoutFunction }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      key: "account",
      type: "group",
      label: "",
      children: [
        // {
        //   label: (
        //     <div
        //       className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
        //       onClick={() => {
        //         // if (location.pathname === "/portals") {
        //         //   return;
        //         // } else {
        //         //   navigate("/portals");
        //         // }
        //       }}
        //     >
        //       <MyProfileNavIcon
        //         fill={location.pathname === "/portals" ? "#59C080" : "#454545"}
        //         width="20px"
        //         height="20px"
        //       />
        //       <span
        //         className="fs-6 ff-ws"
        //         style={{
        //           color:
        //             location.pathname === "/portals" ? "#59C080" : "#454545",
        //         }}
        //       >
        //         My Profile
        //       </span>
        //     </div>
        //   ),
        //   key: "portal-credentials",
        // },
        ...(sessionStorage.getItem("governifyRole") === "2"
          ? [
              {
                label: (
                  <div
                    className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
                    onClick={() => {
                      if (location.pathname === "/team") {
                        return;
                      } else {
                        navigate("/team");
                      }
                    }}
                  >
                    <TeamMemberNavIcon
                    pathname={location.pathname}
                      fill={
                        location.pathname === "/team" ? "#59C080" : "#454545"
                      }
                      width="20px"
                      height="20px"
                    />
                    <span
                      className="fs-6 ff-ws"
                      style={{
                        color:
                          location.pathname === "/team" ? "#59C080" : "#454545",
                      }}
                    >
                      Team Management
                    </span>
                  </div>
                ),
                key: "team-management",
              },
            ]
          : []),
      ],
    },
    { type: "divider" },
    {
      key: "navigation",
      type: "group",
      label: "Navigation",
      children: [
        {
          label: (
            <a
              href="/"
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <PlusOutlined
                className={`fs-18`}
                style={{
                  color: location.pathname === "/" ? "#59C080" : "#454545",
                }}
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color: location.pathname === "/" ? "#59C080" : "#454545",
                }}
              >
                Service Request
              </span>
            </a>
          ),
          key: "home",
        },
        {
          label: (
            <a
              href="/track-request"
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <CheckListIcon
                fill={
                  location.pathname === "/track-request" ? "#59C080" : "#454545"
                }
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color:
                    location.pathname === "/track-request"
                      ? "#59C080"
                      : "#454545",
                }}
              >
                Request tracking
              </span>
            </a>
          ),
          key: "track-request",
        },
        {
          label: (
            <a
              href="/report"
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
            >
              <FileTextOutlined
                className={`fs-18`}
                style={{
                  color:
                    location.pathname === "/report" ? "#59C080" : "#454545",
                }}
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color:
                    location.pathname === "/report" ? "#59C080" : "#454545",
                }}
              >
                Insights and Reports
              </span>
            </a>
          ),
          key: "report",
        },
      ],
    },
    {
      type: "divider",
    },
    {
      key: "setting",
      type: "group",
      label: "Settings",
      children: [
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
              onClick={() => {
                if (location.pathname === "/portals") {
                  return;
                } else {
                  navigate("/portals");
                }
              }}
            >
              <Credentials
                fill={location.pathname === "/portals" ? "#59C080" : "#454545"}
                width="20px"
                height="20px"
              />
              <span
                className="fs-6 ff-ws"
                style={{
                  color:
                    location.pathname === "/portals" ? "#59C080" : "#454545",
                }}
              >
                Credentials Management
              </span>
            </div>
          ),
          key: "portal-credentials",
        },
        {
          label: (
            <div
              className={`d-flex align-items-center gap-2 text-decoration-none p-1 fs-s`}
              onClick={() => logoutFunction()}
            >
              <Logout />
              <span className="fs-6 ff-ws" style={{ color: "#EF4444" }}>
                Logout
              </span>
            </div>
          ),
          key: "logout",
        },
      ],
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      className=""
    >
      <MenuOutlined
        style={{ color: "#59C080", fontSize: "26px" }}
        onClick={(e) => e.preventDefault()}
      />
    </Dropdown>
  );
};

export default CustomerMenu;
