import { Button, Modal } from "antd";
import { TrashDeleteProfile } from "../../assets/image";

export const DeleteProfileModal = ({
  openDeleteProfileModal,
  handleDeleteProfile,
  setOpenDeleteProfileModal,
  profileToDelete
}) => {

  return (
    <div>
      <Modal
      width={600}
        open={openDeleteProfileModal}
        title={<p style={{fontSize:"24px" , fontWeight:600}}>Delete Profile {profileToDelete.title}</p>}
        centered
        footer={(_, record) => (
          <div style={{display:"flex" , justifyContent:"space-between" , alignItems:"center"}}>
            <Button
              style={{
                color: "#1c2026",
                border: "none",
                background:" rgba(133, 139, 147, 0.09)"

              }}
              onClick={() => {
                setOpenDeleteProfileModal(false);
              }}
            >
              No, Keep
            </Button>
            <Button
              style={{ border: "none" ,
                background: "#EF4444" ,
                color :"white"
               }}
              onClick={handleDeleteProfile}
            >
              Yes, Delete
            </Button>
          </div>
        )}
        onCancel={() => {
          setOpenDeleteProfileModal(false);
        }}
      >
        <div style={{width:"100%" , margin:"auto"}}>
            <p style={{width:"100%" , display:"flex" , justifyContent:"center"}}>{<TrashDeleteProfile/>}</p>
        </div>
        <div style={{width:"100%" , margin:"auto"}}>
            <p style={{width:"100%" , display:"flex" , justifyContent:"center" , fontSize:"20px" , fontWeight:"600" , color:"#202223"}}>Are you sure you want to delete this access profile?</p>
        </div>
        <div style={{width:"100%" , margin:"auto"}}>
            <p style={{width:"100%" , display:"flex" , justifyContent:"center" , fontSize:"16px" , fontWeight:"400" , color:"#6d7175"}}>Please confirm if you want to proceed with removing this access profile.</p>
        </div>

        <div style={{borderTop:"1px solid rgba(133, 139, 147, 0.18)"}}></div>

      </Modal>
    </div>
  );
};
