import { Button, Select, Table } from "antd";
import { CreatePlusIcon } from "../../assets/image";
import { DeleteOutlined } from "@ant-design/icons";

export const ManageAccessTeamTable = ({
  setOpenDrawerInviteUser,
  dataSourceUserTeam,
  dataSourceAccessProfile,
  handleDeleteTeamMember,
  handleChangeUserRole,
  handleChangeAccessProfile,
  handleDeleteTeamMemberModal
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 100,
    },

    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_, record) => (
        <Select
          style={{ width: "100%" }}
          options={[
            { label: "User", value: "0" },
            { label: "Admin", value: "2" },
          ]}
          value={record.role}
          onChange={(e) => handleChangeUserRole(e, record)}
        />
      ),
      width: 100,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
    },

    {
      title: "Access Profile",
      dataIndex: "email",
      key: "email",
      render: (_, record) => (
        <>
          {record.role === "2" ? (
            <>Full Access</>
          ) : (
            <Select
              style={{ width: "100%" }}
              options={dataSourceAccessProfile.map((item) => {
                return { label: item.title, value: item.id };
              })}
              value={record.accessProfile}
              onChange={(e) => handleChangeAccessProfile(e, record)}
            />
          )}
        </>
      ),
      width: 200,
    },

    {
      title: "Invitation Date",
      dataIndex: "invitationDate",
      key: "invitationDate",
      width: 200,
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render:(_ , record) =>(
        <div>

          {record.status === "Accepted" && <Button style={{background:"#E9F9EF" ,  border:"none" , color:"#22C55E"}}>{record.status}</Button>}
          {record.status === "Pending" && <Button style={{background:"#FFF4EC" ,  border:"none" , color:"#FB923C"}}>{record.status}</Button>}
          {record.status === "Expired" && <Button style={{background:"#858B9317" ,  border:"none" , color:"#858B9352"}}>{record.status}</Button>}

        </div>
      ) ,
      width: 100,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      render: (_, record) => (
        <Button
          className="governify-delete-icon"
          type="plain"
          icon={<DeleteOutlined />}
          onClick={() => handleDeleteTeamMemberModal(record)}
        ></Button>
      ),
      width: 80,
      fixed: "right",
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "white",
          padding: "16px",
        }}
      >
        <span style={{ fontSize: "24px", fontyWeight: "600" }}>
          Team Members
        </span>

        <Button
          style={{
            background: "#5ac063",
            color: "white",
            border: "none",
            fontWeight: 600,
            height: "35px",
          }}
          icon={<CreatePlusIcon />}
          iconPosition="end"
          onClick={() => {
            setOpenDrawerInviteUser(true);
          }}
        >
          Add Team Member
        </Button>
      </div>

      <Table
        scroll={{ x: 768 }}
        className="governify-report-table custom-table"
        columns={columns}
        dataSource={dataSourceUserTeam}
        pagination={{ className: "custom-pagination" }}
      />
    </div>
  );
};
